@import './frameworks/bootstrap/_variables';

// Project units & break points
// -------------------------
$units-project: rem;
$units-bp: em; // false=px, em, rem
// Fonts
// -------------------------
$font-1: 'Open Sans', 'Arial', 'Helvetica', 'sans_serif';
$font-2: $font-1;
$font-base: $font-1;
$font-header: $font-2;
$font-input: $font-1;
$fonts-path: '/assets/fonts/';

// Transitions
// -------------------------
$trans-time: 0.3s;
$trans-type: ease; // cubic-bezier(0.670, 0.210, 0.005, 0.885);
// Borders radius
// -------------------------
$radius: 4px;

// Colors
// -------------------------
// General
$white: #fff;
$black: #000;
$red: #d70a0a;
$red2: #ff6060;
$blue: #1884d7;
$blue2: #496ee8;
$blue3: #17a2b8;
$green: #93ce7f;
$green2: #009779;
$green3: #28a745;
$yellow: #f1e40f;
$yellow2: #ffc107;
$purple: #c246c1;
$navy: #2b3c82;
$orange: #d8851a;
$lightGray: #ececec;
$dark: #191919;
$bright: #fafafa;
$gray: #707070;
$grey: $gray;
$gold: #c9ad70;

// Project colors
$c1: $white; // leading color,
$c2: $dark; // darkest, popular color,
$c3: $white; // brightest color,
$c4: $green; // additional to leading color,
$c5: $gray; // borders
$c6: $bright;
$c7: lighten($gray, 45%);
$c8: $lightGray;
$c9: $gold;

// ... and other
$color-text: $c5;
$color-link: $color-text;
$color-link-hover: darken($color-link, 5%);
$color-headings: $c2;
$color-body-bg: $c2;
$color-border: $c5;
$color-footer-bg: $c1;
$color-footer-text: $c3;
$color-footer-link: $c3;
$color-footer-link-hover: darken($color-footer-link, 5%);
$color-input-bg: transparent;
$color-input-text: $color-text;
$color-input-border: $color-border;
$color-input-placeholder: lighten($dark, 20%);
$color-loader-alternate-text: #d0d0d0;
$color-loader-alternate-image: #c0c0c0;

// Layout
// -------------------------
$g: 12px; // grid padding
$input-height: 50px;

// Breakpoints
// -------------------------
$xlg-up: 1920px;
$xlg: 1919px;
$lg-up: 1470px;
$lg: 1469px;
$slg-up: 1120px;
$slg: 1119px;
$md-up: 1040px;
$md: 1039px;
$sm-up: 787px;
$sm: 786px;
$xsm-up: 610px;
$xsm: 609px;
$xs-up: 480px;
$xs: 479px;
$xxs-up: 380px;
$xxs: 379px;
$xlg-up: bp($xlg-up, up); // min-width
$xlg: bp($xlg); // max-width
$lg-up: bp($screen-lg-desktop, up);
$lg: bp($screen-md-max);
$slg-up: bp($slg-up, up);
$slg: bp($slg);
$md-up: bp($screen-md-min, up);
$md: bp($screen-sm-max);
$sm-up: bp($screen-sm, up);
$sm: bp($screen-xs-max);
$xsm-up: bp($xsm-up, up);
$xsm: bp($xsm);
$xs-up: bp($screen-xs, up);
$xs: bp($screen-xs - 1);
$xxs-up: bp($xxs-up, up);
$xxs: bp($xxs);

/* $xs-up */

$topBarHeight: 100px;

/* $xs */

$topBarHeightXs: 86px;

/* AOLO-357 - add padding top and bottom 18px */
