@import './functions-shared';

// Colors
// -------------------------
$white: #fff;
$black: #000;
$red: #d70a0a;
$red2: #ff6060;
$blue: #1884d7;
$blue2: #496ee8;
$blue3: #17a2b8;
$green: #93ce7f;
$green2: #009779;
$green3: #28a745;
$yellow: #f1e40f;
$yellow2: #ffc107;
$purple: #c246c1;
$navy: #2b3c82;
$orange: #e6a338;
$lightGray: #ececec;
$dark: #191919;
$bright: #fafafa;
$gray: #707070;
$grey: $gray;
$mint: #e6f2ef;

// Project colors
$c1: #e6a338;
$c1-hi: #f5b652;
$c2: #1e1e1e;
$c2-hi: #727272;
$c3: #fff;
$c3-lo: #fafafa;
$c4-hi: #f7f7f7;
$c4: #dedede;
$c5: #e8e8e8;
$c6: #93ce7f;
$c7: #ff6060;
$c10: $mint;

// ... and other
$color-text: $c2;
$color-link: $color-text;
$color-link-hover: darken($color-link, 5%);
$color-headings: $c2;
$color-body-bg: $c2;
$color-border: $c5;
$color-footer-bg: $c1;
$color-footer-text: $c3;
$color-footer-link: $c3;
$color-footer-link-hover: darken($color-footer-link, 5%);
$color-input-bg: transparent;
$color-input-text: $color-text;
$color-input-border: $color-border;
$color-input-placeholder: lighten($dark, 20%);
$color-minimum-alert: $c10;
$color-loader-alternate-text: #d0d0d0;
$color-loader-alternate-image: #c0c0c0;

// Project units & break points
// -------------------------
$units-project: rem;
$units-bp: em; // false=px, em, rem

$font-1: 'San Francisco', 'Open Sans', arial, helvetica, sans-serif;
$font-2: consolas, 'Courier New', monospace;
$font-size-base: 16px;
$wrapper-max-width: 1236px;
$g: 40px;

// Breakpoints
// -------------------------
$xlg-up: 1920px;
$xlg: 1919px;
$lg-up: 1470px;
$screen-lg-desktop: $lg-up;
$lg: 1469px;
$screen-md-max: $lg;
$slg-up: 1120px;
$slg: 1119px;
$md-up: 1040px;
$screen-md-min: $md-up;
$md: 1039px;
$screen-sm-max: $md;
$sm-up: 768px;
$screen-sm: $sm-up;
$sm: 767px;
$screen-xs-max: $sm;
$xsm-up: 610px;
$xsm: 609px;
$xs-up: 480px;
$screen-xs: $xs-up;
$xs: 479px;
$xxs-up: 380px;
$xxs: 379px;
$xlg-up: bp($xlg-up, up); // min-width
$xlg: bp($xlg); // max-width
$lg-up: bp($screen-lg-desktop, up);
$lg: bp($screen-md-max);
$slg-up: bp($slg-up, up);
$slg: bp($slg);
$md-up: bp($screen-md-min, up);
$md: bp($screen-sm-max);
$sm-up: bp($screen-sm, up);
$sm: bp($screen-xs-max);
$xsm-up: bp($xsm-up, up);
$xsm: bp($xsm);
$xs-up: bp($screen-xs, up);
$xs: bp($screen-xs - 1);
$xxs-up: bp($xxs-up, up);
$xxs: bp($xxs);
$trans-type: ease;
$trans-time: 0.5s;

// $xlg-up:bp($xlg-up, up); // min-width
// $xlg:bp($xlg); // max-width
// $lg-up:bp($lg-up, up);
// $lg:bp($lg);
// $slg-up:bp($slg-up, up);
// $slg:bp($slg);
// $md-up:bp($md-up, up);
// $md: bp($md);
// $sm-up:bp($sm-up, up);
// $sm:bp($sm);
// $xsm-up:bp($xsm-up, up);
// $xsm:bp($xsm);
// $xs-up: bp($xs-up, up);
// $xs: bp($xs);
// $xxs-up:bp($xxs-up, up);
// $xxs:bp($xxs);
