// WebKit-style focus

@mixin tab-focus() {
    // Default
    outline: thin dotted;

    // WebKit
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
