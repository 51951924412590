@charset "UTF-8";

@import '../config/__index';

/* Haven't fully switched to BEVM - require more tests, etc. */

/* Using BEM mixins - reffer to frameworks/bevem/_mixins */

// ////////////////////////////////////////////////////
// ----------------------------------------------------
//
// Basic adjustments
//
// ----------------------------------------------------
// ////////////////////////////////////////////////////

@include text-selector(rgba($c4, 0.9), $c3);

html {
    font-size: $font-size-base;
}

body {
    font-family: var(--font-primary, $font-base);
    color: var(--color-main-brand-color, $color-text);
    background-color: var(--color-main-brand-color, $c2);
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;

    // &.modal-open {
    //     overflow: hidden;
    // }

    &.with-loyalty-messages {
        background-color: var(--loyalty-messages-bg, $c1);
    }
}

a {
    color: $color-link;
    text-decoration: none;
    transition-property: color;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: $trans-type;

    &:hover,
    &:focus {
        color: $link-hover-color;
    }
}

.table-block {
    display: table;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    &__cell {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
}

// ////////////////////////////////////////////////////
// ----------------------------------------------------
//
// additionals global helpers/modifiers `.--...`
// and more adjustments go here
//
// ----------------------------------------------------
// ////////////////////////////////////////////////////
// https://stackoverflow.com/questions/15093939/why-are-bullets-still-showing-after-list-stylenone

/* media query only applies style to IE10 and IE11 */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* removes bullets in list items for IE11 */

    /* You may need the next line: #page_bonds #videos li */
    li {
        list-style-position: outside;
        overflow: hidden;
    }
}

[id*='anchor-'] {
    display: block;
}

/* Disable IE 'things' */

::-ms-clear,
input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
    display: none;
}
