@charset "UTF-8";

@import '../../__index';

// SNIPPET: 'rlistbem'

@mixin resetListBEM() {
    padding: 0;
    margin: 0;

    &__li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        list-style-image: none;
    }
}

// BEM
// -------------------------
/// Block Element
/// @access public
/// @param {String} $element - Element's name
// SNIPPET: 'e/'

@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
// SNIPPET: 'bemm'

@mixin modifier($modifier) {
    &--#{$modifier},
    &.-#{$modifier} {
        @content;
    }
}

// SNIPPET: 'bemm'
/// @alias modifier

@mixin m($modifier) {
    &--#{$modifier},
    &.-#{$modifier} {
        @content;
    }

    // @include modifier($modifier) {
    //     @content;
    // }
}

// SNIPPET: 'bemflagm'

@mixin flagM($selector) {
    /* can style child element depending on passed flag 'string' - no dots, dashes in the beggining */
    &.-#{$selector} &,
    &--#{$selector} & {
        @content;
    }
}

// SNIPPET: 'bemflag'

@mixin flag($selector: '') {
    /* can style child element depennding on passed flag 'string' - can be ID, attribute, class, anything - more custom than above */
    &#{$selector} & {
        @content;
    }
}

/* How to use MODIFIERS with flags */
// .nav-list {@include resetListBEM;
//     //@include m(test) {background-color:white;}

//     // @include flagClass('test') {
//     //     &__li {background-color:red;}
//     // };

//     // @include flag('.is-active') {
//     //     &__li {color:blue}
//     // };
// }

/// @alias element
// SNIPPET: 'e/'

@mixin e($element) {
    // -> &__$element { .. }

    @include element($element) {
        @content;
    }
}
