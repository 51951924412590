@charset "UTF-8";

@import '../../__index';

// ////////////////////////////////////////////////////
// ----------------------------------------------------
//
// SINGLE HELPERS MODIFIERS `g-...`
//
// ----------------------------------------------------
// ////////////////////////////////////////////////////
.__line {
    display: block;
}

.__color-success {
    color: $green3;
}

.__color-error {
    color: $red;
}

.__bgc-c1 {
    background-color: $c1;
}

.__bgc-c2 {
    background-color: $c2;
}

.__bgc-c3 {
    background-color: $c3;
}

.__bgc-c4 {
    background-color: $c4;
}

.__clear {
    @include clear;
}

.__cursor-default {
    cursor: default;
}

.__cursor-pointer {
    cursor: pointer;
}

.__ellipsis {
    @include ellipsis;
}

.__fleft {
    float: left;
}

.__fright {
    float: right;
}

.__nofloat {
    float: none;
}

.__text-center {
    text-align: center;
}

.__text-justify {
    text-align: justify;
}

.__text-left {
    text-align: left;
}

.__text-right {
    text-align: right;
}

.__text-bigger {
    font-size: 115%;
}

.__text-big {
    font-size: 140%;
}

.__text-smaller {
    font-size: 85%;
}

.__text-small {
    font-size: 60%;
}

.__uppercase {
    text-transform: uppercase;
}

.__lowercase {
    text-transform: lowercase;
}

.__text-notransform {
    text-transform: none;
}

.__italic {
    font-style: italic;
}

.__underline {
    text-decoration: underline;
}

.__nounderline {
    text-decoration: none;
}

.__width-full {
    width: 100%;
}

.__width-half {
    width: 50%;
}

.__block,
.g-line {
    display: block;

    @include clear;
}

.__margin-top {
    margin-top: $g;
}

.__margin-top2 {
    margin-top: $g * 2;
}

.__margin-top3 {
    margin-top: $g * 3;
}

.__margin-top4 {
    margin-top: $g * 4;
}

.__margin-bottom {
    margin-bottom: $g;
}

.__margin-bottom2 {
    margin-bottom: $g * 2;
}

.__margin-bottom3 {
    margin-bottom: $g * 3;
}

.__margin-bottom4 {
    margin-bottom: $g * 4;
}

.__padding-top {
    padding-top: $g;
}

.__padding-top2 {
    padding-top: $g * 2;
}

.__padding-top3 {
    padding-top: $g * 3;
}

.__padding-top4 {
    padding-top: $g * 4;
}

.__padding-bottom {
    padding-bottom: $g;
}

.__padding-bottom2 {
    padding-bottom: $g * 2;
}

.__padding-bottom3 {
    padding-bottom: $g * 3;
}

.__padding-bottom4 {
    padding-bottom: $g * 4;
}

.__weight-100,
.g-weight-thin {
    font-weight: 100;
}

.__weight-200,
.g-weight-extra-light {
    font-weight: 200;
}

.__weight-300,
.g-weight-light {
    font-weight: 300;
}

.__weight-400,
.g-weight-normal {
    font-weight: 400;
}

.__weight-500,
.g-weight-medium {
    font-weight: 500;
}

.__weight-600,
.g-weight-semibold {
    font-weight: 600;
}

.__weight-700,
.g-weight-strong,
.g-weight-bold {
    font-weight: 700;
}

.__weight-800 {
    font-weight: 800;
}

.__weight-900,
.g-weight-black {
    font-weight: 900;
}

.__visible-xlg-up {
    @media ($xlg-up) {
        display: block;
    }
}

.__visible-xlg-down {
    @media ($xlg) {
        display: block;
    }
}

.__visible-lg-up {
    @media ($lg-up) {
        display: block;
    }
}

.__visible-lg-down {
    @media ($lg) {
        display: block;
    }
}

.__visible-md-up {
    @media ($md-up) {
        display: block;
    }
}

.__visible-md-down {
    @media ($md) {
        display: block;
    }
}

.__visible-sm-up {
    @media ($sm-up) {
        display: block;
    }
}

.__visible-sm-down {
    @media ($sm) {
        display: block;
    }
}

.__visible-xsm-up {
    @media ($xsm-up) {
        display: block;
    }
}

.__visible-xsm-down {
    @media ($xsm) {
        display: block;
    }
}

.__visible-xs-up {
    @media ($xs-up) {
        display: block;
    }
}

.__visible-xs-down {
    @media ($xs) {
        display: block;
    }
}

.__visible-xxs-up {
    @media ($xxs-up) {
        display: block;
    }
}

.__visible-xxs-down {
    @media ($xxs) {
        display: block;
    }
}

.__hidden-xlg-up {
    @media ($xlg-up) {
        display: none;
    }
}

.__hidden-xlg-down {
    @media ($xlg) {
        display: none;
    }
}

.__hidden-lg-up {
    @media ($lg-up) {
        display: none;
    }
}

.__hidden-lg-down {
    @media ($lg) {
        display: none;
    }
}

.__hidden-md-up {
    @media ($md-up) {
        display: none;
    }
}

.__hidden-md-down {
    @media ($md) {
        display: none;
    }
}

.__hidden-sm-up {
    @media ($sm-up) {
        display: none;
    }
}

.__hidden-sm-down {
    @media ($sm) {
        display: none;
    }
}

.__hidden-xsm-up {
    @media ($xsm-up) {
        display: none;
    }
}

.__hidden-xsm-down {
    @media ($xsm) {
        display: none;
    }
}

.__hidden-xs-up {
    @media ($xs-up) {
        display: none;
    }
}

.__hidden-xs-down {
    @media ($xs) {
        display: none;
    }
}

.__hidden-xxs-up {
    @media ($xxs-up) {
        display: none;
    }
}

.__hidden-xxs-down {
    @media ($xxs) {
        display: none;
    }
}

.__hidden,
.g-hide {
    display: none;
}

.__pre-line {
    white-space: pre-line;
}

// body {
//     .g-bgc-c1 {background-color: $c1;}
//     .g-bgc-c2 {background-color: $c2;}
//     .g-bgc-c3 {background-color: $c3;}
//     .g-bgc-c4 {background-color: $c4;}
//     .g-clear {@include clear;}
//     .g-cursor-default {cursor: default;}
//     .g-cursor-pointer {cursor: pointer;}
//     .g-ellipsis {@include ellipsis;}
//     .g-fleft {float: left;}
//     .g-fright {float: right;}
//     .g-nofloat {float: none;}
//     .g-text-center {text-align: center;}
//     .g-text-justify {text-align: justify;}
//     .g-text-left {text-align: left;}
//     .g-text-right {text-align: right;}
//     .g-text-bigger {font-size: 115%;}
//     .g-text-big {font-size: 140%;}
//     .g-text-smaller {font-size: 85%;}
//     .g-text-small {font-size: 60%;}
//     .g-uppercase {text-transform: uppercase;}
//     .g-lowercase {text-transform: lowercase;}
//     .g-text-notransform {text-transform: none;}
//     .g-italic {font-style: italic;}
//     .g-underline {text-decoration: underline;}
//     .g-nounderline {text-decoration: none;}
//     .g-width-full {width: 100%;}
//     .g-width-half {width: 50%;}
//     .g-block, .g-line {display: block;@include clear;}
//     .g-margin-top {margin-top: $g;}
//     .g-margin-top2 {margin-top: $g*2;}
//     .g-margin-top3 {margin-top: $g*3;}
//     .g-margin-top4 {margin-top: $g*4;}
//     .g-margin-bottom {margin-bottom: $g;}
//     .g-margin-bottom2 {margin-bottom: $g*2;}
//     .g-margin-bottom3 {margin-bottom: $g*3;}
//     .g-margin-bottom4 {margin-bottom: $g*4;}
//     .g-padding-top {padding-top: $g;}
//     .g-padding-top2 {padding-top: $g*2;}
//     .g-padding-top3 {padding-top: $g*3;}
//     .g-padding-top4 {padding-top: $g*4;}
//     .g-padding-bottom {padding-bottom: $g;}
//     .g-padding-bottom2 {padding-bottom: $g*2;}
//     .g-padding-bottom3 {padding-bottom: $g*3;}
//     .g-padding-bottom4 {padding-bottom: $g*4;}
//     .g-weight-100, .g-weight-thin {font-weight: 100;}
//     .g-weight-200, .g-weight-extra-light {font-weight: 200;}
//     .g-weight-300, .g-weight-light {font-weight: 300;}
//     .g-weight-400, .g-weight-normal {font-weight: 400;}
//     .g-weight-500, .g-weight-medium {font-weight: 500;}
//     .g-weight-600, .g-weight-semibold {font-weight: 600;}
//     .g-weight-700, .g-weight-strong, .g-weight-bold {font-weight: 700;}
//     .g-weight-800 {font-weight: 800;}
//     .g-weight-900, .g-weight-black {font-weight: 900;}
//     .g-visible-xlg-up {@media ($xlg-up) {display:block;}}
//     .g-visible-xlg-down {@media ($xlg) {display:block;}}
//     .g-visible-lg-up {@media ($lg-up) {display:block;}}
//     .g-visible-lg-down {@media ($lg) {display:block;}}
//     .g-visible-md-up {@media ($md-up) {display:block;}}
//     .g-visible-md-down {@media ($md) {display:block;}}
//     .g-visible-sm-up {@media ($sm-up) {display:block;}}
//     .g-visible-sm-down {@media ($sm) {display:block;}}
//     .g-visible-xsm-up {@media ($xsm-up) {display:block;}}
//     .g-visible-xsm-down {@media ($xsm) {display:block;}}
//     .g-visible-xs-up {@media ($xs-up) {display:block;}}
//     .g-visible-xs-down {@media ($xs) {display:block;}}
//     .g-visible-xxs-up {@media ($xxs-up) {display:block;}}
//     .g-visible-xxs-down {@media ($xxs) {display:block;}}
//     .g-hidden-xlg-up {@media ($xlg-up) {display:none;}}
//     .g-hidden-xlg-down {@media ($xlg) {display:none;}}
//     .g-hidden-lg-up {@media ($lg-up) {display:none;}}
//     .g-hidden-lg-down {@media ($lg) {display:none;}}
//     .g-hidden-md-up {@media ($md-up) {display:none;}}
//     .g-hidden-md-down {@media ($md) {display:none;}}
//     .g-hidden-sm-up {@media ($sm-up) {display:none;}}
//     .g-hidden-sm-down {@media ($sm) {display:none;}}
//     .g-hidden-xsm-up {@media ($xsm-up) {display:none;}}
//     .g-hidden-xsm-down {@media ($xsm) {display:none;}}
//     .g-hidden-xs-up {@media ($xs-up) {display:none;}}
//     .g-hidden-xs-down {@media ($xs) {display:none;}}
//     .g-hidden-xxs-up {@media ($xxs-up) {display:none;}}
//     .g-hidden-xxs-down {@media ($xxs) {display:none;}}
//     .g-hidden, .g-hide {display: none;}
// }
