@import '../../../../../src/scss/admin/mixins-shared';

@import './_functions';

@mixin blankBlink {
    @include animation(fadeInOut, 0.5s, loop);
}

@mixin topBarBtnsPadding {
    padding-top: (($topBarHeightXs - 35) / 2) - 1;

    @media ($xs-up) {
        padding-top: (($topBarHeight - 35) / 2) - 1;
    }
}

@mixin hs() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .g-h1,
    .g-h2,
    .g-h3,
    .g-h4,
    .g-h5,
    .g-h6 {
        @content;
    }
}

@mixin clear {
    clear: both;

    &::before,
    &::after {
        display: block;
        clear: both;
        content: ' ';
    }
}

@mixin noclear {
    clear: none;

    &::before,
    &::after {
        display: none;
        clear: none;
    }
}

@mixin eqbox($h, $adjust: 0, $targetUnits: px) {
    @if not unitless($h) {
        $h: strip-unit($h);
    }

    $a: $h - $adjust;

    line-height: #{$a}#{$targetUnits};
    height: #{$h}#{$targetUnits};
}

@mixin text-selector($bg: none, $cl: none) {
    @if ($bg != none) {
        ::-moz-selection {
            /* Code for Firefox */
            color: $cl;
            background-color: $bg;
        }

        ::selection {
            color: $cl;
            background-color: $bg;
        }
    }
}

// restyle placeholders

@mixin placeholder {
    &:-moz-placeholder {
        @include opacity(1);
        @content;
    }

    // Firefox 4-18
    &::-moz-placeholder {
        @include opacity(1);
        @content;
    }

    // Firefox 19+
    &:-ms-input-placeholder {
        @include opacity(1);
        @content;
    }

    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        @include opacity(1);
        @content;
    }

    // Safari and Chrome
}

// remove link outlines when clicked

@mixin outline-remove {
    outline: 0 !important;
    outline-style: none;

    &::-moz-focus-inner,
    &::-moz-focus-outer,
    &:focus,
    &:active,
    &:hover {
        outline: 0 !important;
        outline-style: none !important;
    }
}

// add ellipsis

@mixin ellipsis() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// FONT ICONS
// -------------------------

@mixin addIcon($family, $sign, $position: before, $lineheight: 0) {
    $font-family: 'Glyphicons Halflings' !default;

    // FONT TYPE

    @if ($family == 'task') {
        $font-family: 'taskicon';
    }

    @if ($family == 'glyph') {
        $font-family: 'Glyphicons Halflings';
    }

    @if ($family == 'fa') {
        $font-family: 'FontAwesome';
    }

    @if ($family == 'ion') {
        $font-family: 'Ionicons';
    }

    @if ($family == 'ti') {
        $font-family: 'themify';
    }

    @if ($family == 'drip') {
        $font-family: 'dripicons';
    }

    @if ($family == 'feather') {
        $font-family: 'feather';
    }

    @if ($family == 'material') {
        $font-family: 'Material Icons';
    }

    @if ($family == 'fontello') {
        $font-family: 'Fontello';
    }

    // /*line-height: $lineheight;vertical-align:middle;*/
    &:#{($position)} {
        font-style: normal !important;
        display: inline-block;
        content: unicode($sign);
        text-transform: none !important;
        font-weight: 400 !important;
        font-family: $font-family;
        text-rendering: optimizelegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        @content;
    }
}

// GENERATE TRIANGLES
// -------------------------

@mixin triangleall() {
    width: 0;
    height: 0;
    border-style: solid;
    display: block;
    content: ' ';
}

@mixin triangle($type: r, $width: 10px, $height: 10px, $color: $main-color-theme, $position: before) {
    $bc: transparent;
    $bw: 0;

    @if ($type == t) {
        $bc: transparent transparent $color transparent;
        $bw: 0 $width / 2 $height $width / 2;
    }

    @if ($type == tr) {
        $bc: transparent $color transparent;
        $bw: 0 $width $width 0;
    }

    @if ($type == r) {
        $bc: transparent transparent transparent $color;
        $bw: $height / 2 0 $height / 2 $width;
    }

    @if ($type == br) {
        $bc: transparent transparent $color transparent;
        $bw: 0 0 $width $width;
    }

    @if ($type == b) {
        $bc: $color transparent transparent transparent;
        $bw: $height $width / 2 0 $width / 2;
    }

    @if ($type == l) {
        $bc: transparent $color transparent transparent;
        $bw: $height / 2 $width $height / 2 0;
    }

    @if ($type == tl) {
        $bc: $color transparent transparent transparent;
        $bw: $width $width 0 0;
    }

    @if ($type == bl) {
        $bc: transparent transparent transparent $color;
        $bw: $width 0 0 $width;
    }

    @if ($position == after) {
        &::after {
            @include triangleall;

            border-color: $bc;
            border-width: $bw;
            @content;
        }
    } @else {
        &::before {
            @include triangleall;

            border-color: $bc;
            border-width: $bw;
            @content;
        }
    }
}

// ANIMATIONS
// -------------------------

@mixin animated($time) {
    animation-fill-mode: both;
    animation-duration: #{$time};
}

@mixin animated_hinge($time) {
    animation-duration: #{$time};
}

@mixin backface_vis() {
    backface-visibility: visible !important;
}

// main function

@mixin animation($animacja, $czas, $loop) {
    @include animated($time: $czas);

    animation-name: #{$animacja};

    $loop: null !default;

    @if ($loop == 'loop') {
        animation-iteration-count: infinite;
    }

    @if ($animacja == 'swing') {
        transform-origin: top center;
    }

    @if ($animacja == 'wiggle') {
        animation-timing-function: ease-in;
    }

    @if ($animacja == 'flip') {
        transform-style: preserve-3d;

        @include backface_vis;
    }

    @if ($animacja == 'flipInX') {
        @include backface_vis;
    }

    @if ($animacja == 'flipOutX') {
        @include backface_vis;
    }

    @if ($animacja == 'flipInY') {
        @include backface_vis;
    }

    @if ($animacja == 'flipOutY') {
        @include backface_vis;
    }

    @if ($animacja == 'lightSpeedIn') {
        animation-timing-function: ease-out;
    }

    @if ($animacja == 'lightSpeedOut') {
        animation-timing-function: ease-in;
    }
}

// GRADIENTS
// -------------------------
// OBSOLETE

@mixin ieNoFilter {
    /* html.no-filter & {filter:none;} */
}

// up -> down

@mixin gradient($start_color, $end_color) {
    background: $start_color;
    background: linear-gradient(to bottom, $start_color 0%, $end_color 100%);

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{($start_color)}', endColorstr='#{($end_color)}',GradientType=0 );

    @include ieNoFilter;
}

// left -> right

@mixin hgradient($start_color, $end_color) {
    background: $start_color;
    background: linear-gradient(to right, $start_color 0%, $end_color 100%);

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{($start_color)}', endColorstr='#{($end_color)}',GradientType=1 );

    @include ieNoFilter;
}

// skewed top left -> bottom right

@mixin d1gradient($start_color, $end_color) {
    background: $start_color;
    background: linear-gradient(135deg, $start_color 0%, $end_color 100%);

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{($start_color)}', endColorstr='#{($end_color)}',GradientType=1 );

    @include ieNoFilter;
}

// skewed bottom left -> top right

@mixin d2gradient($start_color, $end_color) {
    background: $start_color;
    background: linear-gradient(45deg, $start_color 0%, $end_color 100%);

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{($start_color)}', endColorstr='#{($end_color)}',GradientType=1 );

    @include ieNoFilter;
}

// radial

@mixin radgradient($start_color, $end_color) {
    background: $start_color;
    background: radial-gradient(ellipse at center, $start_color 0%, $end_color 100%);

    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{($start_color)}', endColorstr='#{($end_color)}',GradientType=1 );

    @include ieNoFilter;
}

@mixin stripedGradient($color1: rgba(255, 0, 0, 0.6), $color2: rgba(230, 0, 0, 0.6), $angle: -45, $size: 30) {
    background-image: repeating-linear-gradient(#{$angle}deg, $color1, $color1 #{$size}px, $color2 #{$size}px, $color2 #{$size * 2}px);
}
