// Opacity

@mixin opacity($opacity) {
    opacity: $opacity;

    // IE8 filter
    $opacity-ie: ($opacity * 100);

    filter: alpha(opacity=$opacity-ie);
}
