@charset "UTF-8";

@import '../config/__index';

// ////////////////////////////////////////////////////
// ----------------------------------------------------
//
// Layout - major components and general components
// styling. https://smacss.com/book/type-layout
//
// ----------------------------------------------------
// ////////////////////////////////////////////////////

// Header
// -------------------------

header#main-header {
    position: relative;
    z-index: 10;
}

// Footer
// -------------------------

footer {
    background-color: $color-footer-bg;
    color: $color-footer-text;

    a {
        color: $color-footer-link;
    }
}

table {
    position: relative;

    tr {
        background-color: #f0f0f0;
        border-bottom: 1px solid white;
    }
}

.page-sec {
    &.-shadow {
        box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    }

    &.-type-bar {
        background-color: $c3;
    }
}
