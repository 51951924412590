@keyframes elementFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fromLeftOffsetToRightOffset {
    0% {
        transform: translate(-100%, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@keyframes cartIn {
    0% {
        margin-top: -50px;
        opacity: 0;
    }

    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes cartOut {
    0% {
        margin-top: 0;
        opacity: 1;
    }

    100% {
        margin-top: -50px;
        opacity: 0;
    }
}

@keyframes popIn {
    0% {
        top: 100%;
        opacity: 0;
    }

    100% {
        top: 50%;
        opacity: 1;
    }
}

@keyframes popOut {
    100% {
        top: 100%;
        opacity: 0;
    }

    0% {
        top: 50%;
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        left: 150%;
    }

    100% {
        opacity: 1;
        left: 50%;
    }
}

@keyframes slideOutToRight {
    100% {
        opacity: 1;
        left: 150%;
    }

    0% {
        opacity: 0;
        left: 50%;
    }
}

@keyframes slideInFromLeft {
    0% {
        left: -100%;
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    0% {
        left: 0;
        opacity: 1;
    }

    100% {
        left: -100%;
        opacity: 0;
    }
}
