@import '../admin/_index';

.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--wrapper-max-width, $wrapper-max-width);
    padding-left: calc(var(--grid-gutter-width, $g) / 2);
    padding-right: calc(var(--grid-gutter-width, $g) / 2);

    &.-relative {
        position: relative;
    }
}

.row {
    margin-left: calc(var(--grid-gutter-width, $g) / -2);
    margin-right: calc(var(--grid-gutter-width, $g) / -2);
    display: flex;
    flex-wrap: wrap;
}

.col {
    position: relative;
    padding-left: calc(var(--grid-gutter-width, $g) / 2);
    padding-right: calc(var(--grid-gutter-width, $g) / 2);
    max-width: 100%;
    width: 100%;
    flex-grow: 1;
    flex-basis: 0;

    &.-width-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
}

.w-100 {
    /* Clear column - new row */
    width: 100% !important;
}
