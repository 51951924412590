/* Import some bootstrap stuff */

@import './config/frameworks/bootstrap/_bootstrap';

/* Import BEvM */

@import './config/frameworks/bevm_alpha/__index';

/* Import custom fonts */

/* Some additional stuff, might be hosted localy or imported from google fonts */
// @import url('//fonts.googleapis.com/css?family=Montserrat:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&font-display=swap');
// @import url('//fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&font-display=swap');

@import './config/_fonts';

/* ...and add additional if you want here */

/* Material https://material.angular.io/guide/getting-started */

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* Import some global styles */

/* Base styles */

@import '../../../../src/scss/shared';

@import './main/__index';
