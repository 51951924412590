@charset "UTF-8";

@import '../../__index';

// ////////////////////////////////////////////////////
// ----------------------------------------------------
//
// STATES `is-...`
//
// ----------------------------------------------------
// ////////////////////////////////////////////////////

.is- {
    &hidden,
    &hide,
    &none,
    &invisible {
        display: none;
    }

    &visible,
    &block {
        display: block;
    }

    // &collapsed {height: 0px;overflow: hidden;}
}

.__is-collapsed {
    display: none;
}
.__is-expanded {
    display: block;
}
