@import '../admin/_index';

table.mat-calendar-table {
    tr {
        background-color: transparent;
        border-bottom: none;
    }
}

.mat-button-focus-overlay {
    display: none;
}
